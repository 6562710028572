import {
  AdobePhotoShopeIcon,
  FigmaIcon,
  FramerIcon,
  KotlinIcon,
  WordpressIcon,
  AndroidIcon,
  JavaIcon,
  JavaScriptIcon
} from '../resources/icons'

const skills = [
  {
    title: 'Android',
    icon: <AndroidIcon />,
    count: 95,
  },
  {
    title: 'Java',
    icon: <JavaIcon />,
    count: 80,
  },
  {
    title: 'Kotlin',
    icon: <KotlinIcon />,
    count: 85,
  },
  {
    title: 'JavaScript',
    icon: <JavaScriptIcon />,
    count: 75,
  },
  {
    title: 'Figma',
    icon: <FigmaIcon />,
    count: 60,
  },
]

function MySkills() {
  return (
    <div id='skills' className="bg-primary bg-cover bg-no-repeat h-full w-full mx-auto relative">
      <div className="container mx-auto lg:px-[6.75rem] px-5 lg:py-40 py-6">
        <div className="w-full text-center">
          <div className="text-xl text-white uppercase">My Skills</div>
          <div className="lg:text-[3.125rem] text-2xl mt-3">
            <span className="text-white">My</span>{' '}
            <span className="text-secondary">Development Skills</span>
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 md:mt-16 mt-8 gap-5 relative z-10">
          {skills?.map((skill, index) => (
            <div key={index} className="group">
              <div className="border border-white rounded-lg px-4 pt-4 cursor-pointer">
                <div className="flex items-center justify-between gap-x-5">
                  <div className="flex items-center gap-x-4">
                    <span>{skill.icon}</span>
                    <span className="text-[1.375rem] font-semibold text-white">
                      {skill.title}
                    </span>
                  </div>
                  <div className="border px-4 py-2 border-[#B5B5B5] rounded-full">
                    <span className="text-base font-semibold group-hover:text-secondary text-white">
                      {skill.count}%
                    </span>
                  </div>
                </div>
                <div className="mx-5 mt-[3.313rem]">
                  <div className="h-[0.313rem] w-full group-hover:bg-[#005C33] bg-gray-400 mt-4">
                    <div
                      className="group-hover:bg-secondary bg-gray-100 h-[0.313rem]"
                      style={{ width: Math.min(skill.count, 100) + '%' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 left-0 h-3/4 w-1/2 z-0"></div>
    </div>
  )
}

export default MySkills
