import { Branding, DesignIcon, UIUX } from '../resources/icons'

function Services() {
  return (
    <div className="bg-primary bg-cover bg-no-repeat h-full w-full mx-auto relative">
      <div className="container mx-auto lg:px-[6.75rem] px-5 lg:py-40 py-6">
        <div className="w-full text-center">
          <div className="text-xl text-white uppercase">Services</div>
          <div className="lg:text-[3.125rem] text-2xl mt-3">
            <span className="text-white">My</span>{' '}
            <span className="text-secondary">Specializations</span>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col justify-between items-center md:mt-16 mt-8 gap-5">
          <div className="border border-white rounded-lg p-4">
            <div>
              <DesignIcon />
            </div>
            <div className="text-white font-bold text-lg lg:mt-6 mt-3">
              Mobile App Development
            </div>
            <div className="text-white font-normal text-sm lg:mt-6 mt-3">
              I specialize in crafting high-quality mobile applications, delivering seamless and engaging experiences on both iOS and Android platforms.
            </div>
          </div>
          <div className="border border-white rounded-lg p-4">
            <div>
              <Branding />
            </div>
            <div className="text-white font-bold text-lg lg:mt-6 mt-3">
              React Native Expertise
            </div>
            <div className="text-white font-normal text-sm lg:mt-6 mt-3">
              As a React Native specialist, I build efficient cross-platform solutions, ensuring consistent performance and user experience across all devices.
            </div>
          </div>
          <div className="border border-white rounded-lg p-4">
            <div>
              <UIUX />
            </div>
            <div className="text-white font-bold text-lg lg:mt-6 mt-3">
              Mobile App Research
            </div>
            <div className="text-white font-normal text-sm lg:mt-6 mt-3">
              I excel in mobile app research, staying abreast of the latest technologies and trends to create innovative and future-ready applications.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 right-10 h-3/4 w-1/2"></div>

    </div>
  )
}

export default Services
