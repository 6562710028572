function EducationAndExperience() {
  return (
    <div id="education" className="bg-hero_image bg-cover bg-no-repeat h-full w-full mx-auto relative">
      {/* <div className="bg-primary px-[6.75rem] py-[5.688rem]"> */}
      <div className="container mx-auto lg:px-[6.75rem] px-5 lg:py-[5.688rem] py-6">
        <div className="w-full text-center">
          <div className="text-xl text-white uppercase">Resume</div>
          <div className="lg:text-[3.125rem] text-2xl mt-3">
            <span className="text-white">Education &</span>{' '}
            <span className="text-secondary"> Experience</span>
          </div>
        </div>

        <div className="flex items-center justify-between w-full mt-16">
          <div>
            {/* 2020 - Present */}
            <div className="flex">
              <div className="flex flex-col justify-center items-center">
                <div className="bg-gray-500 p-1 rounded-full"></div>
                <div className="bg-gray-500 w-[2px] h-full"></div>
              </div>

              <div className="w-full lg:mx-14 mx-2 lg:py-8 py-4">
                <div className="text-[#717171] lg:text-xl text-base font-medium">
                  2018 - 2022
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    Master's Degree, Computer Software Engineering 
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    University of Bedfordshire 
                  </div>
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    Senior Mobile App Developer
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    CrawlApps Infotech LLP
                  </div>
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    Senior Mobile App Developer
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    Weapplinse Technology
                  </div>
                </div>
              </div>
            </div>

            {/* 2013 - 2019 */}
            <div className="flex">
              <div className="flex flex-col justify-center items-center">
                <div className="bg-secondary p-1 rounded-full"></div>
                <div className="bg-secondary w-[2px] h-full"></div>
              </div>

              <div className="w-full  lg:mx-14 mx-2 lg:py-8 py-4">
                <div className="text-secondary lg:text-xl text-base font-medium">
                  2016 - 2017
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium ">
                    Senior Android App Developer
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    Coldfin Lab Pvt.Ltd.
                  </div>
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    Junior Android App Developer
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    Ved Solution
                  </div>
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    Intern Android App Developer
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    Ved Solution
                  </div>
                </div>
              </div>
            </div>

            {/* 2010 - 2013 */}
            <div className="flex">
              <div className="flex flex-col justify-center items-center">
                <div className="bg-gray-500 p-1 rounded-full"></div>
                <div className="bg-gray-500 w-[2px] h-full"></div>
              </div>
              <div className="w-full  lg:mx-14 mx-2 lg:py-8 py-4">
                <div className="text-[#717171] lg:text-xl text-base font-medium">
                  2011 - 2016
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    MSC ICT
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    VNSGU, Surat
                  </div>
                </div>

                <div className="pt-8">
                  <div className="text-white lg:text-[2rem] text-2xl font-medium">
                    BCA
                  </div>
                  <div className="text-[#717171] lg:text-xl text-base font-medium">
                    Smt. Tanuben and Dr. Manubhai Trivedi College of Information Science
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-hero_bottom_image bg-no-repeat absolute lg:-bottom-20 bottom-0 left-0 h-full w-full"></div>
        </div>
      </div>
    </div>
  )
}

export default EducationAndExperience
