import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import heroPic from '../resources/divya_queen.png'

import NavBar from './NavBar'
import SideNavBar from './SideNavBar'

const textItems = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
]

const Hero = () => {
  return (
    <div id="hero" className="bg-hero_image bg-cover bg-no-repeat min-h-screen h-full w-full mx-auto relative flex items-center">
      <div className="container mx-auto pt-16 lg:pt-0"> {/* Added padding top for mobile */}
        <NavBar />
        <div className="flex flex-col md:flex-row mx-auto px-8 justify-between items-center">
          <div className="flex flex-col lg:flex-row items-center px-4 lg:px-20">
            <img src={heroPic} alt="hero" className="w-[300px] md:w-[500px]" />
            <div className="md:p-8 max-w-3xl">
              <p className="text-white text-4xl tracking-tight hero-title">
                Hi, I am
                <br />
                <TypeAnimation
                  sequence={[
                    'Software Developer',
                    1000,
                    'Mobile Developer',
                    1000,
                    'Full Stack Developer',
                    1000,
                  ]}
                  speed={50}
                  repeat={Infinity}
                  className="hero-title-type"
                />
              </p>

              <h2 className="hero-exp">with 5+ years of experience</h2>
              <p className="text-base leading-[2.125rem] mt-20 font-normal tracking-wide text-[#D1D5DB]">
                A mobile app developer shaping digital experiences for over 5
                years now. I craft intuitive and impactful applications that
                balance user needs with business goals, specializing in React
                Native for cross-platform excellence.
              </p>
            </div>
          </div>

          <div className="hidden lg:block fixed right-8 top-12 z-10">
            <SideNavBar />
          </div>
        </div>

        {/* Background Image for the bottom right */}
        <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-10 right-10 h-[500px] w-[700px]"></div>
      </div>
      {/* Working on bottom logo */}
      {/* <div className="h-40 w-40 bg-primary absolute right-[10%]"></div> */}
      {/* <div className="relative flex items-center justify-center w-64 h-64">
      <div className="relative flex items-center justify-center w-64 h-64">
      {textItems.map((text, index) => (
        <span
          key={index}
          className="absolute text-blue-500"
          style={{
            transform: `rotate(${index * (360 / textItems.length)}deg) translate(0, -400%)`,
            transformOrigin: '0% 100%',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </span>
      ))}
    </div>
    </div> */}
    </div>
  )
}

{
  /* <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 right-0"></div> */
}

export default Hero
