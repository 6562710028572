import React, { useState, useEffect } from 'react'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import hamburgerMenu from '../resources/ic_menu.svg'

const NavBar = () => {
  const [nav, setNav] = useState(false)
  const [showName, setShowName] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      if (scrollPosition > 100) {
        setShowName(false)
      } else {
        setShowName(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleNav = () => {
    setNav(!nav)
  }

  const closeNav = () => {
    setNav(false)
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
      closeNav()
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-transparent flex justify-between items-center h-24 site-title p-8 w-full">
      {showName && (
        <a href="/" className="site-title text-2xl">
          Divya
        </a>
      )}
      {!showName && <div className="w-16" />} {/* Placeholder to maintain layout */}
      <img
        className="cursor-pointer w-12 h-12 mx-5 md:mx-10"
        src={hamburgerMenu}
        alt="side-menu"
        onClick={handleNav}
      />
      {nav && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed inset-y-0 right-0 max-w-full w-full sm:w-64 shadow-xl" style={{ backgroundColor: '#162251' }}>
            <div className="flex justify-end p-4">
              <AiOutlineClose
                className="text-3xl cursor-pointer"
                onClick={closeNav}
              />
            </div>
            <nav className="px-4">
              <ul className="space-y-4">
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('hero')}>Home</span></li>
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('profile')}>About</span></li>
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('education')}>Education</span></li>
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('skills')}>Skills</span></li>
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('portfolio')}>Portfolio</span></li>
                <li><span className="text-xl cursor-pointer" onClick={() => scrollToSection('contact')}>Contact me</span></li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavBar
