import { useState } from 'react'
import { ArrowForwardIcon, BagIcon } from '../resources/icons'
import project1 from '../resources/portfolio/project1.png'
import project2 from '../resources/portfolio/project2.png'
import project3 from '../resources/portfolio/project3.png'

const projects = [
  {
    imgSrc: project3,
    title: 'Relik - Admin Dashboard1',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project1,
    title: 'Clicon - E-commerce Website2',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project2,
    title: 'Relik - Admin Dashboard3',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project1,
    title: 'Clicon - E-commerce Website4',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project2,
    title: 'Relik - Admin Dashboard5',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project1,
    title: 'Clicon - E-commerce Website6',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project3,
    title: 'Relik - Admin Dashboard7',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
  {
    imgSrc: project2,
    title: 'Clicon - E-commerce Website8',
    desc:
      '👉 Relik is a beautiful, simple, developer-friendly, highly customizable admin dashboard template with a 💎 high-quality UI & well-organized Figma file🔥.',
  },
]

function Portfolio() {
  const [project, setProject] = useState(4)

  return (
    <div id='portfolio' className="bg-primary bg-cover bg-no-repeat h-full w-full mx-auto relative">
      <div className="container mx-auto lg:px-[6.75rem] px-5 lg:py-40 py-6">
        <div className="w-full text-center">
          <div className="text-xl text-white uppercase">Portfolio</div>
          <div className="lg:text-[3.125rem] text-2xl mt-3">
            <span className="text-white">Featured</span>{' '}
            <span className="text-secondary">Projects</span>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 xl:gap-14 gap-7 md:mt-16 mt-8 relative z-10">
          {projects?.slice(0, project).map((project, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="group relative overflow-hidden rounded-2xl cursor-pointer">
                <img
                  src={project.imgSrc}
                  alt="project"
                  className="w-full h-auto"
                />
                <div className="absolute inset-0 bg-[#121314] opacity-0 transition-opacity duration-300 group-hover:opacity-85">
                  <div className="px-12 flex justify-center items-center h-full">
                    <div>
                      <div className="flex gap-x-4 justify-center">
                        <button className="bg-[#ECEEF3] text-[#0026C2] text-xs leading-[1.125rem] font-semibold py-2 px-4 rounded-lg">
                          Website
                        </button>
                        <button className="bg-[#ECEEF3] text-[#0026C2] text-xs leading-[1.125rem] font-semibold py-2 px-4 rounded-lg">
                          Figma
                        </button>
                      </div>
                      <div className="text-2xl font-semibold text-secondary text-center mt-4">
                        {project.title}
                      </div>
                      <div className="text-sm font-normal text-white text-center mt-6">
                        {project.desc}
                      </div>
                      <div className="flex justify-center">
                        <button className="border-2 border-secondary rounded-lg p-[0.938rem] text-white mt-6 flex gap-x-2 items-center justify-center">
                          <span className="text-secondary text-sm font-semibold">
                            Figma
                          </span>
                          <span className="bg-secondary p-1 rounded-full">
                            <ArrowForwardIcon size={8} />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[1.75rem] font-semibold text-white mt-4 text-center">
                {project.title}
              </div>
            </div>
          ))}
        </div>

        {projects.length > project && (
          <div className="flex justify-center items-center mt-10">
            <button
              className="bg-[#ECEEF3] flex gap-x-3 px-8 py-4 rounded-lg w-auto"
              onClick={() => setProject(projects.length)}
            >
              <span>
                <BagIcon />
              </span>
              <span className="text-[#0026C2] text-base font-bold">
                Load more project...
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 right-0 h-3/4 w-1/2 z-0"></div>
    </div>
  )
}

export default Portfolio
