import logo from './logo.svg'
import './App.css'
import NavBar from './components/NavBar'
import Hero from './components/Hero'
import SideNavBar from './components/SideNavBar'
import Aboutme from './components/Aboutme'
import EducationAndExperience from './components/EducationAndExperience'
import Services from './components/Services'
import MySkills from './components/MySkills'
import Portfolio from './components/Portfolio'
import Testimonial from './components/Testimonials'
import Contact from './components/Contact'
function App() {
  return (
    <div>
      {/* <SideNavBar /> */}
      <Hero />
      <Aboutme />
      <EducationAndExperience />
      <Services />
      <MySkills />
      <Portfolio />
      {/* <Testimonial /> */}
      <Contact />
      {/* <div className='flex flex-col justify-center items-center h-screen'>
    <h1 className='text-white text-4xl font-bold'>Welcome to my Portfolio</h1>
   </div> */}
    </div>
  )
}

export default App
