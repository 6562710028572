import {
  Linkedin,
  Mail,
  PhoneCall,
  SendHorizontal,
} from 'lucide-react'

import { ArrowForwardIcon, UnsplashIcon } from '../resources/icons'
import { FaFacebook } from 'react-icons/fa'
import { FaInstagram, FaTwitter } from 'react-icons/fa6'

function Contact() {
  return (
    <div id='contact' className="bg-primary bg-cover bg-no-repeat h-full w-full mx-auto relative">
      <div className="container mx-auto lg:px-[6.75rem] px-5 pb-12">
        <div className="grid lg:grid-cols-2 grid-cols-1 xl:gap-20 md:gap-12 gap-5">
          <div>
            <div className="lg:text-[3.5rem] text-4xl lg:leading-[4rem] text-white font-semibold">
              Enough Talk! Let’s Work Together.
            </div>
            <div className="lg:text-xl text-base lg:leading-[1.875rem text-[#D8D8D8] mt-8">
              Do you fancy saying hi to me or do you want to get started with
              your project and you need my help? Feel free to contact me.
            </div>
            <button className="lg:text-base text-sm text-[#0026C2] font-bold bg-secondary lg:py-3 py-2 lg:px-8 px-4 flex justify-center items-center gap-x-2 rounded-lg mt-4">
              <span> Hire me</span>
              <span>
                <ArrowForwardIcon color="#0026C2" size={13} />
              </span>
            </button>
            <div className="lg:mt-10 mt-5 flex gap-x-6 items-center">
              <div className="flex justify-center items-center gap-x-4">
                <div className="border border-white p-3 rounded-lg">
                  <PhoneCall className="text-white" />
                </div>
                <div>
                  <div className="lg:text-sm text-xs font-normal text-white">
                    Call me now
                  </div>
                  <div className="lg:text-base text-sm font-semibold text-white">
                    +1-202-555-0190{' '}
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center gap-x-4">
                <div className="border border-white p-3 rounded-lg">
                  <Mail className="text-white" />
                </div>
                <div>
                  <div className="lg:text-sm text-xs font-normal text-white">
                    Chat with me
                  </div>
                  <div className="lg:text-base text-sm font-semibold text-white">
                    kevin.gilbert@gmail.com
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-x-4 mt-10">
              <button className="bg-white p-[0.875rem] rounded-full">
                <UnsplashIcon />
              </button>
              <button className="bg-white p-[0.875rem] rounded-full">
                <FaFacebook className="h-5 w-5 text-[#0026C2]" />
              </button>
              <button className="bg-white p-[0.875rem] rounded-full">
                <FaTwitter className="h-5 w-5 text-[#0026C2]" />
              </button>
              <button className="bg-white p-[0.875rem] rounded-full">
                <Linkedin className="h-5 w-5 text-[#0026C2]" />
              </button>
              <button className="bg-white p-[0.875rem] rounded-full">
                <FaInstagram className="h-5 w-5 text-[#0026C2]" />
              </button>
            </div>
          </div>

          <div className='lg:mt-0 mt-10'>
            <div className="text-2xl font-semibold">
              <span className="text-white">Just say</span> 👋{' '}
              <span className="text-secondary"> Hi </span>
            </div>
            <form className='mt-6'>
              <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="Full name"
                  className="border border-[#717171] rounded-lg bg-primary px-4 py-3 w-full"
                />
                <input
                  type="email"
                  placeholder="email address"
                  className="border border-[#717171] rounded-lg bg-primary px-4 py-3 w-full"
                />
              </div>
              <div className="mt-4">
                <textarea
                  placeholder="Message"
                  className="border border-[#717171] rounded-lg bg-primary px-4 py-3 w-full"
                />
              </div>
              <button className="px-6 py-2 bg-white rounded-lg mt-6 flex gap-x-3">
                <span className="text-base font-semibold text-primary">
                  Send Message
                </span>
                <span>
                  <SendHorizontal className="h-6 w-6" />
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 right-0 h-3/4 w-1/2 z-0"></div>
    </div>
  )
}

export default Contact
