import React from 'react';

const Aboutme = () => {
    return (
        <div id="profile">
            <div className="container mx-auto lg:py-[5.688rem] py-6">
            <h3 className='about-me-title text-xl'>ABOUT ME</h3>
            <p className='text-4xl about-me-story text-center py-5'>
                {'Every innovative app begins with an'}<br />
                {'even better idea'}
            </p>

            <p className='text-base about-me-subtitle text-center py-5 px-10 md:px-40'>
                {'I\'m Divya, a mobile app developer dedicated to crafting intuitive and impactful digital experiences. With a passion for user-centric development, I transform ideas into functional and engaging applications. Let\'s collaborate and shape the future of technology together. Since beginning my journey over 5 years ago, I\'ve done remote work for agencies, consulted for startups, and collaborated with talented teams to create mobile apps for both business and consumer use. Specializing in React Native, I build cross-platform solutions that balance user needs with business goals.'}
            </p>

            {/* New section for statistics */}
            <div className='flex justify-around items-center w-full mt-10'>
                <div className='text-center'>
                    <h4 className='text-4xl project-count'>5+</h4>
                    <p className='text-lg project-count-title '>Years Experience</p>
                </div>
                <div className='text-center'>
                    <h4 className='text-4xl project-count'>18</h4>
                    <p className='text-lg project-count-title '>Projects Done</p>
                </div>
                <div className='text-center'>
                    <h4 className='text-4xl project-count'>10</h4>
                    <p className='text-lg project-count-title '>Clients Served</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Aboutme;