import {
  ArrowBackwardIcon,
  ArrowForwardIcon,
  DoubleQuotesIcon,
} from '../resources/icons'
import user from '../resources/divya.png'
import logo_one from '../resources/testimonials/logo_one.png'
import logo_two from '../resources/testimonials/logo_two.png'

function Testimonial() {
  return (
    <div className="bg-primary bg-cover bg-no-repeat h-full w-full mx-auto relative">
      <div className="container mx-auto lg:px-[6.75rem] px-5 lg:py-40 py-6">
        <div className="w-full text-center">
          <div className="text-xl text-white uppercase">Testimonial</div>
          <div className="lg:text-[3.125rem] text-2xl mt-3">
            <span className="text-white">Trusted by</span>{' '}
            <span className="text-secondary capitalize">Hundred clients</span>
          </div>
        </div>
        <div className="pt-16 pb-[7.5rem] 2xl:mx-40 xl:mx-20 md:mx-10 mx-5 ">
          <div>
            <div className="bg-[#ECEEF3] p-10 rounded-2xl relative">
              <div className="">
                <DoubleQuotesIcon />
              </div>
              <div className="lg:text-2xl text-xl lg:leading-9 font-medium mt-6">
                “Great Designer, does great work and is very flexible with
                change. if you’re a programmer and are looking for UI/UX
                designer is definitely well qualified for the job.”
              </div>
              <div className="absolute w-0 h-0 border-l-[16px] border-r-[16px] border-t-[24px] border-l-transparent border-r-transparent border-b-[#ECEEF3] left-8 bottom-[-23px]"></div>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-5 mt-16">
            <div className="flex justify-center items-center gap-x-3">
              <img
                src={user}
                alt="user"
                className="h-16 w-16 bg-white rounded-xl"
              />
              <div>
                <div className="text-lg text-white leading-6 font-semibold">
                  Cameron Williamson
                </div>
                <div className="text-base font-semibold text-secondary">
                  Founder of Relik
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center gap-x-3">
              <div className="border-2 border-secondary rounded-full sm:p-3 p-1">
                <ArrowBackwardIcon color="white" />
              </div>
              <div className="border-2 border-secondary rounded-full sm:p-3 p-1">
                <ArrowForwardIcon color="white" />
              </div>
            </div>
          </div>

          <div className="mt-[7.5rem]">
            <div className="text-lg font-medium text-white">
              I work with over 279+ happy clients all over the world.
            </div>
            <div className="flex flex-wrap justify-center gap-6 mt-6 w-full relative z-10">
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
              <div className="flex justify-center items-center">
                <div className="bg-logo_one hover:bg-logo_two bg-no-repeat h-12 w-[8.5rem] p-2 border hover:border-secondary cursor-pointer rounded-lg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-hero_bottom_image bg-no-repeat absolute bottom-0 left-0 h-3/4 w-1/2 z-0"></div>
    </div>
  )
}

export default Testimonial
