import React from 'react'
import { Home, User, Briefcase, BookPlus, Mail,GraduationCap } from 'lucide-react'
import { LineIcon } from '../resources/icons'

const SideNavBar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const NavItem = ({ icon, sectionId }) => (
    <div
      className="w-10 h-10 rounded-full bg-navy-700 flex items-center justify-center text-gray-400 hover:bg-green-400 hover:text-navy-900 transition-colors cursor-pointer"
      onClick={() => scrollToSection(sectionId)}
    >
      {icon}
    </div>
  )

  return (
    <nav className="bg-navy-800 flex flex-col items-center justify-between py-8 px-4 mr-7 space-y-8">
      <div className='rotate-180'>
        <LineIcon startColor={"#999999"} endColor={"#E1E1E1"} />
      </div>
      <div>
        <div className="space-y-8">
          <NavItem icon={<Home size={20} />} sectionId="hero" />
          <NavItem icon={<User size={20} />} sectionId="profile" />
          <NavItem icon={<GraduationCap size={20} />} sectionId="education" />
          <NavItem icon={<BookPlus size={20} />} sectionId="skills" />
          <NavItem icon={<Briefcase size={20} />} sectionId="portfolio" />
        </div>
        <div className="mt-8">
          <NavItem icon={<Mail size={20} />} sectionId="contact" />
        </div>
      </div>
      <div>
        <LineIcon />
      </div>
    </nav>
  )
}

export default SideNavBar
